import { languages } from "../../src/public/js/languages";
import { Session } from '../scripts/session'

//##############################################################################################################
// ARQUIVO DE CONSTANTES GLOBAIS
//##############################################################################################################

export const Constant = {
    TITLE: "Portal ISP BR | Plataforma de Gestão FTTh"
};

export const CodePage = {
    CONSULTA_ENDERECO: "9C58BF8B8B69E007A0417EE767DFAD4330DC86002E5BFB924BD8F74C3634DA75",
    CONSULTA_VIABILIDADE: "4D29E6DC8D0BE8962F3962E891A5F4191AD5885D576BA191C0D184C12304F970",

    RESERVA_FACILIDADES: "44C51E06A31DBF0B0754E8D38000B961DA8315C35B89BEB811B2AF052EB2F331",
    CONSULTA_FACILIDADES: "B8F0FFA37D44DEAF5A66D6586B9EF602AD61E8AC907F03D92B32C5DE6B87F2D2",
    CANCELAMENTO_RESERVA: "B8DC3C9519A7B7B621B2D790634A3935452070649B2DDA6F58AAD979BE3F5ECE",

    CANCELAMENTO_CIRCUITO_ATIVO: "B8DC3C9519A7B7B621B2D790634A3935452070649B2DDA6F58AAD979BE3F5EFF",

    ATIVACAO_RECURSO: "B3B5431BDF806B128C23B369210C1C15B4F2534D6A494CEB2083D7EABD79CF67",
    CERTIFICACAO_SERVICO: "964A0E155ECD4DC5A1B9747DD221C593A0BCB4EC935B0D3434D217A7B48E252A",
    SUSPENSAO_RECURSO: "272674B6A5DD9B6FBB0606F085D8B1AF699DA377535BE2A48159B3FDA4DD3A52",
    DESBLOQUEIO_RECURSO: "A01D3213551AF22D05A5305B58801C72E7D2EDDF1556957057342A9E1043CF82",
    DESATIVACAO_RECURSO: "0F12308AF837E51D89EB04A1AF29439E69063F9596ED2C0A6336AB68DA14F636",
    TROCA_VELOCIDADE: "8115C2F8B6243715B7516922C204A241A3E39A2226267BFE06F4CA14BD3F4FA0",
    TROCA_SERVICO: "42E76033862DB10E7FF868613A02C4430AF529223D9E44A2BBCE03F1833A02E1",
    TROCA_EQUIPAMENTO: "D7BA031E422569CA3D4ACCDF9B79326C629D38EA163986C059D3AEB8C1AE3F6D",
    GESTAO_MERCADOS: "7EE3E58A8F4DE6C0521597B5CA6094C0C05BFD622F0675EB16B29BD4535D8939",
    TESTE_DIAGNOSTICO: "279C98D4B3FF63FD40AC27A1075D4B7A2FBDAD12FEB6B7319D5A37906F827EE5",
    REAPLICACAO_CONFIG: "392C36A792799ECB3556F4249612F108F29BC165A7CF1B1AC371D13C2B9563EB",
    REBOOT_ONT: "5F7989998BF06F644FEB6DE04FEEBFE61BA23FB8C21F1BF994701AE337CC8685",
    MANOBRA_CTO: "",
    ESTATISTICA_TRAFEGO: "CBF20846DF90E68D36D3B4BEAF0364586DDED56F33ADF3F682817CE29068B650",
    ALAMES_ONT: "49A9A9503E56C3D3D310604C69D9896B8F9EBC308441CB4574614090BF7FA59C",
    INCIDENTES_MASSIVOS: "376C471F3726C8B38A06FE852982DA3C4505DE460E7E030873672334C3CAC34C",
    ATIVIDADE_PROGRAMADA: "F98C36C8B5C77968669FFEC3981A289249A44A30DB0627995770A039F6B7D8E3",
    SUPORTE_INSTALACAO: "DDF2F699EEAFC90913938951D0D4FED753309D2DA00DE8B9EFD50BD155335735",
    SOLICITACAO_REPARO: "4E2572E3381C240781B074005747759C28B3FD1E47EAFA0E950F2A9C3B3C4350",
    CRITICA_ORQUESTRACAO: "20CB417FC7C4E19D82B509F63D3EE9C1758C096E5DDF6C5E90B168B316F703A1",
    RELATORIOS: "DC4BB10B6D41FDBF9B14B37128204F6567E706CABE9677480A550DFA4E8523E0",
    DOCUMENTACAO_APIS: "106F44CC4284C4E9959A0CE1743BF76EA12F37C4DDFC21F27A21D3C622F1D73E",

    GESTAO_CRIACAO_USUARIO: "002A400DC9CDFF681861B52DF8F9D78A162CB902288D145B362D011FB219D1CA",
    GESTAO_ALTERACAO_USUARIO: "002A400DC9CDFF681861B52DF8F9D78A162CB902288D145B362D011FB219D1CA",

    GESTAO_VNO: "2CCD2723769E2EBFBFD17D97891601653F72D70B451FDC84F5755DB1DC30FFA5",
    GESTAO_VNO_CADASTRO: "2CCD2723769E2EBFBFD17D97891601653F72D70B451FDC84F5755DB1DC30FFA5",
    GESTAO_VNO_ALTERAR: "2CCD2723769E2EBFBFD17D97891601653F72D70B451FDC84F5755DB1DC30FFA5",
    GESTAO_VNO_DETALHE: "2CCD2723769E2EBFBFD17D97891601653F72D70B451FDC84F5755DB1DC30FFA5",
    GESTAO_VNO_SERVICO: "2CCD2723769E2EBFBFD17D97891601653F72D70B451FDC84F5755DB1DC30FFA5",

    GESTAO_DASHBOARD: "FD562D1EDFDDF6178AA668648F2CFE49BA6FE72E134058C1725DC7AF983C3D18",
    GESTAO_REPORTS: "7459322EAD35682027CE1E731DA5D0232466200F5F7031B8DD4386B2E63F9660",
    GESTAO_REPORT_INFORMATION: "3567EE228B09C2982EF6953343E3410EE439C1BAB898BCDB6921E90E3F64491E",
    GESTAO_RESERVE_CLEANING: "334F60B6ED1B80C0ED282C2B3AE1C0C4355405F82188F60095C696431ACF568C",
    GESTAO_LOAD: "6857D17E66F24C0DA047EA8433A180E484AE55588DF600267226B53652A1989D",
    GESTAO_REGRA_VIABILIDADE: "5B9A179B5E266DC64474B1F53F1DDBA22D361153BB1C713AF8A248F963140D5B",
    GESTAO_INTEROPERABILIDADE: "AB2BFE2D4DF52C63359CCD08D122BF14B664000BCD063B13D70977B4F2A961F8",
    GESTAO_MANOBRA_MASSIVA: "B255C59794F54468E9F699AA429E28332F5FB7F99C1689E46F96FD2983676D54",
    GESTAO_CRIACAO_USUARIO_ADMIN_ATC: "DCA9742C34D324B94FDA547382041C89D6F1ADC2FA1FDEC8E2AE8F1713157A7B",
    GESTAO_ALTERACAO_USUARIO_ADMIN_ATC: "DCA9742C34D324B94FDA547382041C89D6F1ADC2FA1FDEC8E2AE8F1713157A7B",
    BLANK: "",
    ACESSO_NEGADO: ""
};



export const UrlPage = {
    CONSULTA_ENDERECO: "mapa",
    CONSULTA_VIABILIDADE: "4D29E6DC8D0BE8962F3962E891A5F4191AD5885D576BA191C0D184C12304F970",

    RESERVA_FACILIDADES: "",
    CONSULTA_FACILIDADES: "reserva",
    CANCELAMENTO_RESERVA: "reserva/cancelamento",


    CANCELAMENTO_CIRCUITO_ATIVO: "pos-venda/cancelamento",
    SUSPENSAO_RECURSO: "pos-venda/suspensao",
    DESATIVACAO_RECURSO: "pos-venda/cancelamento",
    TROCA_VELOCIDADE: "pos-venda/alteracao-velocidade",
    TROCA_SERVICO: "pos-venda/alteracao-servico",

    TESTE_DIAGNOSTICO: "suporte-tecnico/diagnostico",
    REAPLICACAO_CONFIG: "suporte-tecnico/reapply",
    REBOOT_ONT: "suporte-tecnico/reset",
    MANOBRA_CTO: "suporte-tecnico/manobra-cto",


    ATIVACAO_RECURSO: "reserva",
    CERTIFICACAO_SERVICO: "964A0E155ECD4DC5A1B9747DD221C593A0BCB4EC935B0D3434D217A7B48E252A",
    DESBLOQUEIO_RECURSO: "A01D3213551AF22D05A5305B58801C72E7D2EDDF1556957057342A9E1043CF82",




    TROCA_EQUIPAMENTO: "suporte-tecnico/alteracao-equipamento",
    GESTAO_MERCADOS: "7EE3E58A8F4DE6C0521597B5CA6094C0C05BFD622F0675EB16B29BD4535D8939",

    ESTATISTICA_TRAFEGO: "suporte-tecnico/statistics",
    ALAMES_ONT: "suporte-tecnico/alarms",



    INCIDENTES_MASSIVOS: "376C471F3726C8B38A06FE852982DA3C4505DE460E7E030873672334C3CAC34C",
    ATIVIDADE_PROGRAMADA: "F98C36C8B5C77968669FFEC3981A289249A44A30DB0627995770A039F6B7D8E3",
    SUPORTE_INSTALACAO: "DDF2F699EEAFC90913938951D0D4FED753309D2DA00DE8B9EFD50BD155335735",
    SOLICITACAO_REPARO: "4E2572E3381C240781B074005747759C28B3FD1E47EAFA0E950F2A9C3B3C4350",
    CRITICA_ORQUESTRACAO: "20CB417FC7C4E19D82B509F63D3EE9C1758C096E5DDF6C5E90B168B316F703A1",
    RELATORIOS: "DC4BB10B6D41FDBF9B14B37128204F6567E706CABE9677480A550DFA4E8523E0",
    DOCUMENTACAO_APIS: "106F44CC4284C4E9959A0CE1743BF76EA12F37C4DDFC21F27A21D3C622F1D73E",
    //GESTAO_USUARIO: "002A400DC9CDFF681861B52DF8F9D78A162CB902288D145B362D011FB219D1CA",

    GESTAO_CRIACAO_USUARIO: "gestao-usuario",
    GESTAO_ALTERACAO_USUARIO: "gestao-usuario/alterar",

    GESTAO_VNO: "gestao-vno",
    GESTAO_VNO_CADASTRO: "gestao-vno/cadastro",
    GESTAO_VNO_ALTERAR: "gestao-vno/alterar",
    GESTAO_VNO_DETALHE: "gestao-vno/detalhe",
    GESTAO_VNO_SERVICO: "gestao-vno/servicos",
    GESTAO_DASHBOARD: "FD562D1EDFDDF6178AA668648F2CFE49BA6FE72E134058C1725DC7AF983C3D18",
    GESTAO_REPORTS: "7459322EAD35682027CE1E731DA5D0232466200F5F7031B8DD4386B2E63F9660",
    GESTAO_REPORT_INFORMATION: "3567EE228B09C2982EF6953343E3410EE439C1BAB898BCDB6921E90E3F64491E",
    GESTAO_RESERVE_CLEANING: "334F60B6ED1B80C0ED282C2B3AE1C0C4355405F82188F60095C696431ACF568C",
    GESTAO_LOAD: "6857D17E66F24C0DA047EA8433A180E484AE55588DF600267226B53652A1989D",
    GESTAO_REGRA_VIABILIDADE: "5B9A179B5E266DC64474B1F53F1DDBA22D361153BB1C713AF8A248F963140D5B",
    GESTAO_INTEROPERABILIDADE: "AB2BFE2D4DF52C63359CCD08D122BF14B664000BCD063B13D70977B4F2A961F8",
    GESTAO_MANOBRA_MASSIVA: "manobra/manobra-cto",
    GESTAO_CRIACAO_USUARIO_ADMIN_ATC: "gestao-usuario",
    GESTAO_ALTERACAO_USUARIO_ADMIN_ATC: "gestao-usuario/alterar",

    BLANK: "blank",
    ACESSO_NEGADO: "acesso-negado",
};



export const DescPage = {
    CONSULTA_ENDERECO: languages[Session.Lang].menu_consulta_endereco_cep_numero,
    CONSULTA_VIABILIDADE: languages[Session.Lang].menu_consulta_endereco,
    RESERVA_FACILIDADES: languages[Session.Lang].menu_consulta_endereco,
    CONSULTA_FACILIDADES: languages[Session.Lang].menu_consulta_facilidades,
    CANCELAMENTO_RESERVA: languages[Session.Lang].menu_cancelamento_reserva,
    CANCELAMENTO_CIRCUITO_ATIVO: languages[Session.Lang].menu_cancelamento_circuito_ativo,
    ATIVACAO_RECURSO: languages[Session.Lang].menu_consulta_facilidades,
    CERTIFICACAO_SERVICO: languages[Session.Lang].menu_consulta_endereco,
    SUSPENSAO_RECURSO: languages[Session.Lang].menu_suspensao_recurso,
    DESBLOQUEIO_RECURSO: languages[Session.Lang].menu_consulta_endereco,
    DESATIVACAO_RECURSO: languages[Session.Lang].menu_desativacao_recurso,
    TROCA_VELOCIDADE: languages[Session.Lang].menu_troca_velocidade,
    TROCA_SERVICO: languages[Session.Lang].menu_troca_servico,
    TROCA_EQUIPAMENTO: languages[Session.Lang].menu_troca_equipamento,
    GESTAO_MERCADOS: languages[Session.Lang].menu_consulta_endereco,
    TESTE_DIAGNOSTICO: languages[Session.Lang].menu_teste_diagnostico,
    REAPLICACAO_CONFIG: languages[Session.Lang].menu_reaplicacao_config,
    REBOOT_ONT: languages[Session.Lang].menu_reboot_ont,
    MANOBRA_CTO: languages[Session.Lang].menu_manobra_cto,
    ESTATISTICA_TRAFEGO: languages[Session.Lang].menu_estatistica_trafego,
    ALAMES_ONT: languages[Session.Lang].menu_alarmes_ont,
    INCIDENTES_MASSIVOS: languages[Session.Lang].menu_consulta_endereco,
    ATIVIDADE_PROGRAMADA: languages[Session.Lang].menu_consulta_endereco,
    SUPORTE_INSTALACAO: languages[Session.Lang].menu_consulta_endereco,
    SOLICITACAO_REPARO: languages[Session.Lang].menu_consulta_endereco,
    CRITICA_ORQUESTRACAO: languages[Session.Lang].menu_consulta_endereco,
    RELATORIOS: languages[Session.Lang].menu_consulta_endereco,
    DOCUMENTACAO_APIS: languages[Session.Lang].menu_consulta_endereco,
    ///GESTAO_USUARIO: languages[Session.Lang].menu_consulta_endereco,

    GESTAO_CRIACAO_USUARIO: languages[Session.Lang].menu_consultar_usuario,
    GESTAO_ALTERACAO_USUARIO: languages[Session.Lang].menu_consulta_facilidades,

    GESTAO_VNO: languages[Session.Lang].menu_gestao_vno,
    GESTAO_VNO_CADASTRO: languages[Session.Lang].menu_gestao_vno_cadastro,
    GESTAO_VNO_ALTERAR: languages[Session.Lang].menu_gestao_vno_cadastro,
    GESTAO_VNO_DETALHE: languages[Session.Lang].menu_gestao_vno_cadastro,
    GESTAO_VNO_SERVICO: languages[Session.Lang].menu_gestao_servico,

    GESTAO_DASHBOARD: languages[Session.Lang].menu_consulta_endereco,
    GESTAO_REPORTS: languages[Session.Lang].menu_consulta_endereco,
    GESTAO_REPORT_INFORMATION: languages[Session.Lang].menu_consulta_endereco,
    GESTAO_RESERVE_CLEANING: languages[Session.Lang].menu_consulta_endereco,
    GESTAO_LOAD: languages[Session.Lang].menu_consulta_endereco,
    GESTAO_REGRA_VIABILIDADE: languages[Session.Lang].menu_consulta_endereco,
    GESTAO_INTEROPERABILIDADE: languages[Session.Lang].menu_consulta_endereco,
    GESTAO_MANOBRA_MASSIVA: languages[Session.Lang].menu_manobra_cto,
    GESTAO_CRIACAO_USUARIO_ADMIN_ATC: languages[Session.Lang].menu_consultar_usuario,
    GESTAO_ALTERACAO_USUARIO_ADMIN_ATC: languages[Session.Lang].menu_alarmes_ont,
    BLANK: languages[Session.Lang].menu_consulta_endereco,
    ACESSO_NEGADO: languages[Session.Lang].menu_consulta_endereco,
}




export const IsMenuPage = {
    CONSULTA_ENDERECO: true,
    CONSULTA_VIABILIDADE: false,
    RESERVA_FACILIDADES: false,
    CONSULTA_FACILIDADES: true,
    CANCELAMENTO_RESERVA: true,
    CANCELAMENTO_CIRCUITO_ATIVO: true,
    ATIVACAO_RECURSO: false,
    CERTIFICACAO_SERVICO: true,
    SUSPENSAO_RECURSO: true,
    DESBLOQUEIO_RECURSO: false,
    DESATIVACAO_RECURSO: true,
    TROCA_VELOCIDADE: true,
    TROCA_SERVICO: true,
    TROCA_EQUIPAMENTO: true,
    GESTAO_MERCADOS: true,
    TESTE_DIAGNOSTICO: true,
    REAPLICACAO_CONFIG: true,
    REBOOT_ONT: true,
    MANOBRA_CTO: true,
    ESTATISTICA_TRAFEGO: true,
    ALAMES_ONT: true,
    INCIDENTES_MASSIVOS: true,
    ATIVIDADE_PROGRAMADA: true,
    SUPORTE_INSTALACAO: true,
    SOLICITACAO_REPARO: true,
    CRITICA_ORQUESTRACAO: true,
    RELATORIOS: true,
    DOCUMENTACAO_APIS: true,
    //GESTAO_USUARIO: true,
    GESTAO_CRIACAO_USUARIO: true,
    GESTAO_ALTERACAO_USUARIO: false,
    GESTAO_VNO: true,
    GESTAO_VNO_CADASTRO: true,
    GESTAO_VNO_ALTERAR: false,
    GESTAO_VNO_DETALHE: false,
    GESTAO_VNO_SERVICO: true,
    GESTAO_DASHBOARD: true,
    GESTAO_REPORTS: true,
    GESTAO_REPORT_INFORMATION: true,
    GESTAO_RESERVE_CLEANING: true,
    GESTAO_LOAD: true,
    GESTAO_REGRA_VIABILIDADE: true,
    GESTAO_INTEROPERABILIDADE: true,
    GESTAO_MANOBRA_MASSIVA: true,
    GESTAO_CRIACAO_USUARIO_ADMIN_ATC: true,
    GESTAO_ALTERACAO_USUARIO_ADMIN_ATC: false,
    BLANK: true,
    ACESSO_NEGADO: true,
};

export const ConstPerfil = {
    ADMIN_ATC: "ADMINATC",
    ATC: "ATC",
    MASTER_ISP: "MASTERISP",
    ISP: "ISP",
};

export const ConstModoOperacaoSigla = {
    OP: "OP",
    ISP: "ISP",
};

export const ConstModoOperacaoValor = {
    OP: "OPERADORA",
    ISP: "ISP",
};

export const ConstPaisOperacaoSigla = {
    BR: "BR",
    ARG: "ARG",
};

export const ConstPaisOperacaoValor = {
    BR: "Brasil",
    ARG: "Argentina",
};

export const ConstPerfilId = {
    ADMINATC: 1,
    ATC: 2,
    MASTERISP: 3,
    ISP: 4,
}; 

export const PasswordRules = {
    MinimumLength: 15,
    HasUpperCase: true,
    HasLowerCase: true,
    HasNumber: true,
    HasSpecialChar: true
}
